<template>
  <div class="vendor-chat-widget border-radius-m "
       :class="{active : expanded,
       'width-expand' : isFullWidth,
       'max-width-300' : ! isFullWidth}">
    <div
      v-if="!expanded"
      @click="expanded = !expanded"
      class="cta-block padding-m cursor-pointer bg-lead c-bg flex flex-middle flex-center text-center text-l border-radius-m text-center">
      <icon-text icon="chat-message">{{ chatCtaTitle }}</icon-text>
    </div>
    <div
      v-if="expanded"
      class="cta-block padding-m cursor-pointer bg-lead c-bg flex flex-middle flex-center text-center text-l border-radius-m text-center">

      <div class="flex-between flex-middle width-expand">
        <icon-halo halo-theme="bg" @click="expanded = !expanded" theme="lead" icon="x" :halo-ratio="0.3" style="margin-inline-end: auto;"></icon-halo>
        <span>{{ chatCtaTitle }}</span>
        <icon-halo
          v-if="!isFullWidth"
          halo-theme="bg"
                   @click="isFullWidth = true"
                   theme="lead" icon="maximize"
                   :halo-ratio="0.3" style="margin-inline-start: auto;"></icon-halo>
        <icon-halo
          v-if="isFullWidth"
          halo-theme="bg"
          @click="isFullWidth = false"
          theme="lead" icon="minimize"
          :halo-ratio="0.3" style="margin-inline-start: auto;"></icon-halo>
      </div>

    </div>
    <div class="chat-block"
         v-if="conversationUuid && expanded"
         style="height: auto; max-height: 50vh">
      <chat

        :conversation-uuid="conversationUuid"
        class="width-expand"></chat>
    </div>
  </div>
</template>

<script>
import useChat from "@/client/extensions/composition/useChat";
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import Chat from "@/client/components/chat/Chat.vue";
// TODO: fix chat UI so the actions always show and the chat show on the rest of the space
// TODO: obtain the conversation if available - show ui if not available
export default {
  components: {
    Chat,
  },
  props: {
    vendorId: {
      type: Number,
      default: false, // this is intentional: must provide number ID
    },
    vendorName: {
      type: [String, Boolean],
      default: false,
    },
  },
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    let { chat } = useChat();
    return { asyncOps, asyncOpsReady, asyncStatus, chat };
  },
  data: function () {
    return {
      conversationUuid: '264d5ff4-f2c3-4d2c-986c-c6039060f83a',
      expanded: false,
      isFullWidth: false,
    };
  },
  watch: {
    expanded(newVal) {
      if ( ! newVal) {
        this.isFullWidth = false;
      }
    }
  },
  computed: {
    chatCtaTitle() {
      if (this.vendorName) {
        return this.translateUcFirst("ecommerce.vendor.chat.ctaTitleGeneric");
      } else {
        return this.translateUcFirst("ecommerce.vendor.chat.ctaTitleSpecific", {
          vendorName: this.vendorName,
        });
      }
    },
  },
  methods: {
  }
};
</script>

<style scoped lang="scss">

.vendor-chat-widget {
  &.width-expand {
    max-width: calc(100lvw - 2 * var(--margin-2xl));
  }
  &.active {
    border: 1px solid var(--c-gray);
    background: var(--c-gray-1);

  }
}
.cta-block {
  background-color: var(--c-lead);
  opacity: 1;
  will-change: opacity;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }
}
</style>
