<template>
  <div class="chat flex-column flex-end" style="{'max-height': height}">

    <div v-if="!conversationUuid">
      <h3>{{ translateUcFirst("chat.message.noConversationTitle") }}</h3>
    </div>

    <div
      ref="messages-wrapper"
      :style="{'height': height}"

      class="messages-wrapper flex-column flex-end overflow-y-auto max-size-parent margin-l-bottom">
      <div
        v-if="messages && conversationUuid"
        class="messages flex-column flex-end size-parent padding-l-bottom"
        style="flex-direction: column-reverse">
        <chat-message
          v-for="message in messages"
          :message="message"
          class="message animate__fadeIn animate--fast">
        </chat-message>
      </div>
    </div>

    <div v-if="conversationUuid" class="actions">
      <div class="flex gap-m flex-middle flex-wrap width-expand padding-bottom">
        <form-input
          v-model="messageText"
          class="message-input width-expand"
          :label="false"
          placeholder="chat.message.sendUi.contentPlaceholder"
          style="min-height: 0px"
          type="textarea"
          @keyup.enter="submitMessageByState()"></form-input>

        <form-button
          class="message-send-cta"
          icon-end="chevron-inline-end"
          text="chat.message.sendUi.sendCta"
          @click="submitMessageByState()"></form-button>
      </div>
    </div>
  </div>
</template>

<script>
import ChatUserAvatar from "@/client/components/chat/UserAvatar.vue";
import ChatMessage from "@/client/components/chat/ChatMessage.vue";
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import { computed } from "vue";
import useChat from "@/client/extensions/composition/useChat";

export default {
  name: "ChatComponent",
  components: {
    ChatMessage,
  },
  props: {
    height: {
      type: String,
      default: "50vh"
    },
    conversationUuid: {
      type: [String, Boolean],
      default: false,
    },
  },
  setup(props) {
    let { asyncOps, asyncOpsReady, asyncStatus, socket } = asyncOperations(props);
    let { chat } = useChat();

    return { asyncOps, asyncOpsReady, asyncStatus, socket: socket.init(), chat };
  },
  data: function () {
    const timestamp = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    const dummyDate = new Date(timestamp);
    const socketDebouncer = utilities.debounce(this.immediateSocketMessageHandler, 200, {
      returnType: "all",
    });

    return {
      messages: false,
      messageText: "",
      socketDebounce: socketDebouncer.debounce,
      socketDebounceClear: socketDebouncer.clear,
      asyncData: {
        messages: {
          target: computed(() => `chat/conversation/${this.conversationUuid}/messages`),
          data: {
            filters: {},
            // pagination: {},
            ordering: { key: "createdAt", direction: "desc" },
          },
          shouldFetch: computed(() => typeof this.conversationUuid === "string"),

          options: {
            dataMutator: (data) => {
              return data.items;
            },
          },
        },
      },
    };
  },
  computed: {
    finalConversationUuid() {
      return this.conversationUuid;
    },
    currentUserId() {
      let profile = this.$store.getters["user/profile"];
      if (!profile) {
        return 0;
      }
      return profile.id;
    },
  },
  watch: {
    messages: {
      handler() {
        this.$nextTick(this.scrollMessagesWrapperToEnd);
      },
      immediate: true,
      deep: true,
    },
    conversationUuid: {
      handler(newVal) {
        if (newVal) {
          this.refreshAsyncData();
        }
      },
      immediate: true,
    }
  },
  async mounted() {
    this.socket = await this.socket;
    await this.socket.waitForUUID();
    this.$nextTick(this.scrollMessagesWrapperToEnd);
    this.socket.on("newChatMessage", this.newSocketMessageHandler);
    this.socket.on("newMessageRead", this.newSocketMessageHandler);
  },
  beforeUnmount() {
    this.socket.off("newChatMessage", this.newSocketMessageHandler);
    this.socket.off("newMessageRead", this.newSocketMessageHandler);
    this.socketDebounceClear();
  },
  methods: {
    immediateSocketMessageHandler(data) {
      this.refreshAsyncData();
    },
    newSocketMessageHandler(data) {
      if (!data.conversationUuid) {
        return;
      }

      if (data.conversationUuid != this.conversationUuid) {
        return;
      }

      this.socketDebounce();
    },
    isMessageFromSelf(message) {
      return message.Sender.uuid === this.$store.getters["user/profile"]?.uuid;
    },
    scrollMessagesWrapperToEnd() {
      if (utilities.isSSR()) {
        return;
      }
      setTimeout(() => {
        try {
          let el = this.$refs["messages-wrapper"];
          el.scrollTop = el.scrollHeight;
        } catch (e) {
          // console.log(e);
        }
      }, 100);
    },
    async submitMessageByState() {
      let result = await this.chat.sendMessage(
        this.finalConversationUuid,
        this.messageText
      );

      if (result.isError) {
        this.$s.ui.notification("chat.message.generalSendError", "error");
        return;
      }

      this.refreshAsyncData();
      this.clearMessage();
    },
    clearMessage() {
      this.messageText = "";
    },
  },
};
</script>

<style scoped lang="scss">
.messages {
  flex: 100%;
  padding-bottom: var(--margin-l);
}

.actions {
  border-top: 1px solid var(--c-gray-2);
  padding: var(--margin-m) var(--margin-m) 0 var(--margin-m);
}
</style>
