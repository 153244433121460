<template>
  <div
    class="user-avatar bg-alt c-bg radius-round flex flex-middle flex-center"
    :class="{ 'has-image': hasProfileImage, ['user-avatar--' + size]: true }">
    <img
      v-if="hasProfileImage"
      class="user-avatar__image object-cover size-parent radius-round width-expand"
      :src="profileImageUrl" />

    <strong v-if="!hasProfileImage" class="text-2xl">{{ letter }}</strong>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "m",
    },
    fullName: {
      type: [String, Boolean],
      default: false,
    },
    profileImageUrl: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    hasProfileImage() {
      return typeof this.profileImageUrl === "string" && this.profileImageUrl !== "";
    },
    letter() {
      if (this.fullName) {
        return this.fullName.charAt(0).toUpperCase();
      }

      return "U";
    },
  },
};
</script>

<style scoped lang="scss">
.user-avatar {
  padding: var(--margin-m);
  width: 3em;
  height: 3em;
  flex: 0 0 3em;
  border: 2px solid var(--c-gray-1);


  &.user-avatar--xs {
    width: 1.5em;
    height: 1.5em;
    flex: 0 0 1.5em;
    padding: var(--margin-m);
    border: 1px solid var(--c-gray-1);
    font-size: var(--font-min);

    &.has-image {
      padding: 0;
      width: 2.5em;
      height: 2.5em;
      flex: 0 0 2.5em;
    }

  }

  &.user-avatar--s {
    width: 2em;
    height: 2em;
    flex: 0 0 2em;
  }

  &.user-avatar--l {
    width: 4em;
    height: 4em;
    flex: 0 0 4em;
  }

  &.has-image {
    padding: 0;
  }
}
.user-avatar {
}
</style>
