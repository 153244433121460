<template>
  <div
    v-if="message && template == 'default'"
    v-bind="$attrs"
    class="chat-message-wrapper width-expand"
    :class="{ 'from-self': isMessageFromSelf }">
    <div class="meta text-center margin-auto-horizontal text-s c-gray-5">
      <span>{{ message.Sender.fullName }}</span>
      <span> | </span>
      <span class="">{{ translateDate(message.createdAt, "dateWithTime") }}</span>
    </div>
    <div class="message-blob flex flex-middle gap-s">
      <chat-user-avatar size="s" v-bind="message.Sender"></chat-user-avatar>
      <div
        class="chat-message relative flex gap-m chat-message--stand-conversation animate__fadeIn animate--fast"
        :class="{ 'from-self': isMessageFromSelf }">

        <div class="visibility-ui">
          <div class="seen-by-all relative" v-if="isSeenByAll"  v-tooltip="{content: 'chat.message.seenByAllTooltip'}">
            <icon  size="1" theme="lead" icon="check-circle" :title="translate('chat.message.seenByAllTooltip')"></icon>
          </div>

          <div class="seen-by-some" v-if="isSeen && !isSeenByAll" v-tooltip="{content: 'chat.message.seenBySomeTooltip'}">
            <icon size="1" theme="lead" icon="check" :title="translate('chat.message.seenBySomeTooltip')"></icon>
          </div>
          <div class="seen-by-none" v-if="isSeenByNone" v-tooltip="{content: 'chat.message.seenByNoneTooltip'}">
            <icon size="1" theme="gray-5" icon="check" :title="translate('chat.message.seenByNoneTooltip')"></icon>
          </div>

        </div>
        <div class="role-ui flex gap-s" v-if=" ! isMessageFromSelf && senderRoles.length > 0">
          <badge size="s" v-for="role in senderRoles">
            <icon-text class="text-s padding-s-inline-end" :icon="role.icon" :text="role.label"></icon-text>
          </badge>
        </div>
        <p>{{ message.content }}</p>
      </div>
    </div>
  </div>
  <div
    v-if="message && template == 'standAlone'"
    v-bind="$attrs"
    class="chat-message chat-message--stand-alone animate__fadeIn animate--fast"
    :class="{ 'from-self': isMessageFromSelf }">
    <div
      class="message-header flex-between flex-wrap flex-middle gap-m"
      :class="{ 'flex-end': isMessageFromSelf }">
      <div class="flex-middle gap-s flex-start">
        <chat-user-avatar size="s" v-bind="message.Sender"></chat-user-avatar>
        <span>{{ message.Sender.fullName }}</span>
      </div>
      <span class="text-s c-gray-5">{{
        translateDate(message.createdAt, "dateWithTime")
      }}</span>
    </div>
    <div
      class="separator bg-gray margin-s-top margin-m-bottom"
      style="height: 1px; width: 100%"></div>
    <p>{{ message.content }}</p>
  </div>
</template>

<script>
import ChatUserAvatar from "@/client/components/chat/UserAvatar.vue";
export default {
  components: {
    ChatUserAvatar,
  },
  props: {
    /**
     * A preset style/layout for the message
     * Values: "default" "standAlone"
     */
    template: {
      type: String,
      default: "default",
    },
    message: {
      type: [Object, Boolean],
      default: false,
    },
  },
  data: function () {
    return {
      roleIconMap: {
        admin : "shield-check",
        vendor: "cart",
        support: "user",
      }
    };
  },
  computed: {
    senderRoles () {
      let result = [];
      if ( ! Array.isArray(this.message.Sender.roles)  ||  this.message.Sender.roles.length <1) {
        return [];
      }
      this.message.Sender.roles.forEach(role => {
        result.push( {
          type: role,
          label: `chat.user.roles.${role}`,
          icon: this.roleIconMap[role] ? this.roleIconMap[role] : false
        })
      });

      return result;
    },
    isMessageFromSelf() {
      if (!this.message) {
        return false;
      }
      return this.message.Sender.uuid === this.$store.getters["user/profile"]?.uuid;
    },
    safeRecipients() {
      if (Array.isArray(this.message.Recipients)) {
        return this.message.Recipients;
      }

      return [];
    },
    isSeenByNone() {
      return ! this.isSeen;
    },
    isSeen() {
      for (const user of this.safeRecipients) {
        if (user.readAt) {
          return true;
        }
      }

      return false;
    },
    isSeenByAll() {
      if (this.safeRecipients.length < 1) {
        return false;
      }

      for (const user of this.safeRecipients) {
        if (!user.readAt) {
          return false;
        }
      }
      return true;
    },
  },
  created() {

  },
};
</script>

<style scoped lang="scss">
.chat-message {
  max-width: 88%;
  margin-inline-end: var(--margin-m);
  margin-top: var(--margin-l);
  margin-inline-start: auto;

  &.from-self {
    align-self: flex-end;
    margin-inline-end: auto;
    margin-inline-start: var(--margin-m);
  }

  @if (mixin-exists(hook-chat-message)) {
    @include hook-chat-message();
  }
}

.chat-message-wrapper {
  margin-top: var(--margin-xl);

  &.from-self {
    .message-blob {
      //flex-direction: row-reverse;
    }
  }

  &:not(.from-self) {
    .message-blob {
      flex-direction: row-reverse;
    }
  }

  .user-avatar {
    //  margin-top: var(--margim-l);
  }

  .chat-message {
    position: relative;
    margin: var(--margin-m);
    flex: 100%;
    border: 1px solid var(--c-gray-2);


    .visibility-ui {
      bottom: 100%;
      margin-bottom: -0.75em;
      inset-inline-start: -0.75em;
      border-radius: 100%;
      background-color: var(--c-bg-4);
      border: 1px solid var(--c-gray-2);
      padding: var(--margin-s);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: var(--z-level-1);
      height: 2em;
      width: 2em;
      text-align: center;
      line-height: 100%;

      .icon {
        position: relative;
        top: 0.15rem;

      }
      .first-icon {
        position: relative;
        inset-inline-start: -0.25em;
      }
      .second-icon {
        position: absolute;
        top: 50%;
        margin-top: -50%;
        inset-inline-start: 50%;
        margin-inline-start: calc(-50% );
      }
      &.seen-by-all {

      }
    }

    .role-ui {
      top: 100%;
      margin-top: -0.75em;
      inset-inline-end: 1em;
      border-radius: var(--border-radius-s);
      background-color: var(--c-bg-4);
      border: 1px solid var(--c-gray-2);
      padding: var(--margin-s) var(--margin-m);
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: var(--z-level-1);
      min-height: 2em;

      text-align: center;
      line-height: 100%;
    }
  }
}
</style>
